* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Epidemic', sans-serif;
}

*::-webkit-scrollbar {
  display: none; /* Ocultar scroll */
}
